import React, { useState, useMemo } from "react";
import { TextField, Container, Typography, Button } from "@material-ui/core";
import { navigate } from "gatsby";

const signUp = async (
  email: string,
  password: string,
  passwordConfirmation: string
) => {
  const res = await fetch("/users.json", {
    method: "POST",
    body: JSON.stringify({
      user: {
        email,
        password,
        password_confirmation: passwordConfirmation,
      },
    }),
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "manual",
  });

  if (res.status === 201) {
    return [true, undefined];
  }

  return [false, (await res.json()).errors];
};

const SignUp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState<string>("");
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>("");

  const [emailErrors, setEmailErrors] = useState<string | undefined>(undefined);
  const [passwordErrors, setPasswordErrors] = useState<string | undefined>(
    undefined
  );
  const [passwordConfirmationErrors, setPasswordConfirmationErrors] = useState<
    string | undefined
  >(undefined);

  return (
    <Container maxWidth="xs">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          margin: "20px 0px",
        }}
      >
        <Typography variant="h1">Sign up</Typography>

        <form
          style={{ width: "100%" }}
          onSubmit={async (e) => {
            e.preventDefault();
            setEmailErrors(undefined);
            setPasswordErrors(undefined);
            setPasswordConfirmationErrors(undefined);
            const [didCreate, errors] = await signUp(
              email,
              password,
              passwordConfirmation
            );

            if (didCreate) {
              const params = new URLSearchParams({
                message:
                  "A message with a confirmation link has been sent to your email address. Please follow the link to activate your account.",
              });
              navigate(`/sign_in?${params}`);
              return;
            }

            if (errors?.email !== undefined) {
              setEmailErrors(
                errors.email
                  .map((error: string) => `Email ${error}`)
                  .join(" and ")
              );
              return;
            }

            if (errors?.password !== undefined) {
              setPasswordErrors(
                errors.password
                  .map((error: string) => `Password ${error}`)
                  .join(" and ")
              );
            }

            if (errors?.password_confirmation !== undefined) {
              setPasswordConfirmationErrors(
                errors.password_confirmation
                  .map((error: string) => `Password confirmation ${error}`)
                  .join(" and ")
              );
            }
          }}
        >
          <TextField
            label="Email"
            margin="normal"
            fullWidth
            variant="outlined"
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            autoComplete="email"
            autoFocus
            error={emailErrors !== undefined}
            helperText={emailErrors}
          />

          <TextField
            label="Password (6 characters minimum)"
            margin="normal"
            fullWidth
            variant="outlined"
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            autoComplete="password"
            error={passwordErrors !== undefined}
            helperText={passwordErrors}
          />

          <TextField
            label="Password confirmation"
            margin="normal"
            fullWidth
            variant="outlined"
            type="password"
            onChange={(e) => setPasswordConfirmation(e.target.value)}
            value={passwordConfirmation}
            autoComplete="password"
            error={passwordConfirmationErrors !== undefined}
            helperText={passwordConfirmationErrors}
          />

          <Button variant="contained" color="primary" fullWidth type="submit">
            Sign up
          </Button>
        </form>
      </div>
    </Container>
  );
};

export default SignUp;
